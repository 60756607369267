export const en_messages = {
  Dashboard: "Dashboard",
  accaunt: "Account",
  access_right: "Access rights",
  access_rights: "Access rights",
  actions: "Actions",
  active: "Active",
  active_patients: "Active patients",
  add: "Add",
  add_branch: "Add branch",
  add_bunk: "Add bunk",
  add_class: "Add class",
  add_conditions: "Add conditions",
  add_corps: "Add corps",
  add_department_user: "Add department user",
  add_departmentes: "Add department",
  add_document: "Add document",
  add_drug_indicator: "Add drug prescription",
  add_drug_indicator_warehouse: "Add drug prescription (warehouse)",
  add_floor: "Add floor",
  add_hospital: "Add hospitalization",
  add_hospitalization_type: "Add hospitalization type",
  add_medicine: "Add medicine",
  add_movement: "Add movement",
  add_nationality: "Add nationality",
  add_new_bunk: "Add new bunk",
  add_new_department: "Add new department",
  add_new_doctor_sign: "Add new signature",
  add_new_partner_clinic: "Add new partner clinic",
  add_new_partner_doctor: "Add new partner doctor",
  add_new_patient: "Add new patient",
  add_new_service: "Add new service",
  add_order_payment: "Add order payment",
  add_partner_clinic: "Add partner clinic",
  add_partner_doctor: "Add partner doctor",
  add_patient_indicators: "Add patient indicators",
  add_patient_physiotherapy: "Add physiotherapy to patient",
  add_physiotherapy: "Add physiotherapy",
  add_processes: "Add process",
  add_purpose_of_hospitalization: "Add purpose of hospitalization",
  add_region: "Add region",
  add_requirement: "Add requirement",
  add_room: "Add room",
  add_service: "Add service",
  add_to_reception: "Add to reception",
  add_to_room: "Add to room",
  add_transaction: "Add transaction",
  add_type_of_injury: "Add type of injury",
  add_unit_of_measure: "Add unit of measure",
  address: "Address",
  address_patient: "Patient address",
  address_register: "Registration address",
  adds: "Add",
  adminPanel: "Admin panel",
  age: "Age",
  agent: "Agent",
  aggrement: "Agreement",
  all_conclusions: "All conclusions",
  all_date: "Date",
  all_laboratory_conclusions: "Laboratory conclusions",
  all_not_paid_price: "Total unpaid amount",
  all_service: "All services",
  all_service_patient: "All services for the patient",
  all_speciality: "All specialties",
  allergy: "Allergy",
  ambulatory: "Ambulatory",
  amount: "Amount",
  another_services: "Other services",
  another_services_income: "Income from other services",
  archive: "Archive",
  arrival_date: "Arrival date",
  ask_about_write_off: "Ask about write-off?",
  authority: "Issuing authority",
  auto_write_off: "Auto write-off",
  available: "Available",
  back: "Back",
  balance: "Balance",
  bank: "Bank",
  bank_account: "Bank account",
  basic_information: "Basic information",
  bed_schedules: "Bed schedules",
  blank_head: "Blank header",
  blok: "Block",
  blood: "Blood group",
  bonus: "Bonus",
  bonus_amount: "Bonus",
  bonus_amount_0: "Bonus amount",
  bonus_date: "Bonus date",
  bonus_debt: "Remaining balance",
  bonus_mode: "Bonus creation mode",
  bonus_paid_amount: "Paid amount",
  bonuses: "Bonuses",
  booked: "Booked",
  born_place: "Place of birth",
  box_available: "Available in box",
  box_quantity: "Quantity in box",
  branch: "Branch",
  branches: "Branches",
  bunk: "Bunk",
  busy: "Occupied",
  buy_ready_product: "Purchase of products",
  c_sum: "sum",
  cabinat_balance: "Client balance",
  cabinet: "Cabinet",
  cach: "Cash",
  cancel: "Cancel",
  cannot_print: "Cannot be printed",
  cargo_storage: "Cargo storage",
  cash: "Salaries",
  cash_amount: "Salary amount",
  cashbox: "Cash register",
  cashbox_print: "Cash register print",
  category: "Category",
  category_template: "Template categories",
  category_or_service: "Category or service",
  category_services: "Service categories",
  change: "Replace",
  change_branch: "Change branch",
  change_write_off: "Change write-off",
  choose_branch: "Choose a branch",
  choose_category: "Choose a category",
  choose_owner_branch: "Choose an owner branch",
  choose_status: "Please select a status",
  choose_template: "Choose a template",
  chose_a_speciality: "Choose a specialty",
  cities: "Cities",
  citizenship: "Citizenship",
  city: "City",
  class: "Class",
  clear: "Clear",
  client: "Client",
  client_name: "Client name",
  clients: "Clients",
  clinic: "Clinic",
  clinic_diagnos: "Clinical diagnosis",
  close: "Close",
  closed_conclusions: "Approved conclusions",
  code: "Code",
  code_id: "Code ID",
  color: "Color",
  comment: "Comment",
  commentary: "Commentary",
  company_name: "Company name",
  complated: "Completed",
  conResourcesText: "Under resources",
  conclusion_date: "Conclusion date",
  conclusions: "Conclusions",
  conclusions_history: "Conclusions history",
  condition: "Condition",
  condition_patient: "Patient condition",
  conditions: "Conditions",
  confirm: "Confirm",
  confirmed: "Confirmed",
  consultation_services: "Consultation",
  consultation_services_income: "Consultation income",
  contact: "Contact person",
  content: "Content",
  contract_parent: "Main contract No.",
  contract_parnet: "Main contract No.",
  contract_payment: "Contract payments",
  contract_service: "Contract services",
  contracts: "Contracts",
  contracts_n: "Contract No.",
  contractual_basis: "On a contractual basis",
  corps: "Corps",
  correspondent_account: "Correspondent account",
  cost_amount: "Cost amount",
  cost_order: "Order cost",
  count: "Count",
  counterparties: "Counterparties",
  countries: "Countries",
  country: "Country",
  country_code: "Country code",
  courier: "Courier",
  couriers: "Couriers",
  create: "Create",
  create_citizenship: "Add citizenship",
  create_city: "New city",
  create_conclusion: "Create a conclusion",
  create_condition: "New condition",
  create_currency: "New currency",
  create_hospitalization: "Add hospitalization",
  refer_to_hospital: "Refer to hospital",
  remove: "Remove",
  pocket: "Pocket",
  create_module: "Create module",
  create_new_order: "Create a new order",
  create_new_patient: "Create a new patient",
  create_order_column: "Field name",
  create_organization: "New organization",
  create_task: "Create task",
  created: "Created",
  created_at: "Creation date",
  currency: "Currency",
  current_price: "Current price",
  daily: "For today",
  dailyProcessReport: "Process report",
  dailyTreatment: "Daily indicator",
  dailyTreatment_patient: "Daily patient indicators",
  daily_hospital: "Day hospital",
  dapartment_user: "Department users",
  dark: "Dark",
  date: "Creation date",
  date_0: "Date",
  date_aggrement: "Agreement date",
  date_hospitalization: "Hospitalization date",
  date_injury: "Disease date",
  date_movement: "Movement date",
  date_of_issue: "Date of issue",
  date_produced: "Production date",
  date_receiving: "Receiving date",
  date_register: "Registration date",
  date_requirement: "Requirement date",
  day: "Day",
  day_hospital: "Day hospital",
  day_hospital_for_month: "Day hospital for the month",
  day_hospital_for_today: "Day hospital today",
  day_hospital_for_week: "Day hospital for the week",
  day_hospital_income: "Day hospital income",
  day_in_warehouse: "Days in stock",
  dds: "DDS",
  deal_amount: "Agreement amount",
  debt_type: "Debt types",
  debtors: "Debtors",
  debts: "Debts",
  defects: "Defects",
  delete: "Delete",
  delete_calcel: "Deletion canceled",
  delete_notification: "This will permanently delete the file. Proceed?",
  delete_notification_confirm: "Are you sure you want to delete it?",
  delete_setting: "Delete",
  delete_success: "Deletion completed",
  delivery_deadline: "Delivery date",
  delivery_time: "Delivery time",
  department: "Department",
  departure_date: "Departure date",
  description: "Description",
  detail: "Details",
  diagnos: "Preliminary diagnosis",
  direction: "Direction",
  disable: "Disable",
  disabled: "Disabled",
  discount: "Discount",
  discount_cost: "Discount price",
  discount_percent: "Discount %",
  discount_price: "Discount (Sum)",
  discounts: "Discounts",
  district: "District",
  do_you_really_want_to_complete_the_hospitalization: "Do you really want to complete the hospitalization?",
  do_you_really_want_to_finish: "Do you really want to finish",
  do_you_want: "Attention, do you really want to do this?",
  do_you_want_confirm: "Do you really want to confirm",
  dob: "Date of birth",
  doctor: "Doctor",
  doctorProcessReport: "Overall process report",
  doctorSign: "Signature",
  doctorSigns: "Signatures",
  doctor_0: "Doctor",
  doctor_bonuses: "Doctor bonuses",
  doctor_can: "Show yourself to your doctor",
  doctor_id: "Doctor ID",
  doctor_name: "Doctor (full name)",
  doctor_service: "Doctor services",
  doctors_settings: "Doctor settings",
  conclusion_template_editor: "Edit conclusion template",
  documents: "Document",
  domain_name: "Domain name",
  domen: "Domain",
  done_treatment: "Done (drug successfully administered)",
  dont_have_file: "No file available",
  download: "Download",
  drive_license: "Driver's license",
  drug: "Medicine",
  drug_bad_reaction: "Defects (Reaction after administering the drug)",
  drug_good_reaction: "Next (No reaction, continue procedure)",
  drug_indicator: "Drug prescription",
  drug_indicator_warehouse: "Drug prescription (warehouse)",
  drug_instruction: "Medicine / Instructions",
  duration: "Duration",
  dynamic_photo: "Dynamic wallpaper",
  edit: "Edit",
  email: "Email",
  email_address: "Email address",
  employees_work_schedule: "Employee work schedule",
  enable: "Enable",
  enabled: "Enabled",
  endPeriod: "Until",
  end_date: "End date",
  end_period: "Until",
  enter_id: "Enter ID",
  enumeration: "Enumeration",
  excelReport: "Excel report",
  exemple: "Example:",
  expence: "Expense",
  expences: "Expenses",
  expired: "Expired",
  express: "Express",
  expresses: "Express",
  fax: "Fax",
  female: "Female",
  file: "File",
  file_upload: "Upload file",
  file_uploaded: "File uploaded",
  filter: "Filters",
  filter_keyword: "Filter keyword",
  final_conclusion: "Discharge",
  fio: "Full name",
  first_name: "First name",
  flat: "Apartment",
  floor: "Floor",
  flow_types: "Expense types",
  footer_header: "Footer",
  footer_image: "Footer image",
  for_bussiness: "Legal entity",
  for_outsource: "For outsourcing",
  for_personal: "Individual",
  form_template_and_signs: "Form templates and signatures",
  form_template_sign: "Form template signature",
  forms: "Forms (ICD-10)",
  forms_mkb: "Forms (ICD-10)",
  conclusion_templates: "Document forms",
  free: "Free",
  from: "From",
  from_hours: "From hours",
  from_time: "Start time",
  from_whom: "From whom",
  full_logo: "Full logo",
  full_name: "Full name",
  gender: "Gender",
  gender_patients: "Patient gender",
  geography_settings: "Geographical settings",
  glass: "Glass",
  go_back: "Go back",
  group: "Group",
  group_of_patient: "Patient age groups",
  groups: "Groups",
  half_logo: "Partial logo",
  half_yearly: "Half yearly",
  height: "Height",
  hidden: "Not specified",
  history: "History",
  history_transaction: "Transaction history",
  hospital: "Hospital",
  hospital_income: "Hospital income",
  hospital_patients_month: "Hospital patients this month",
  hospital_patients_today: "Hospital patients today",
  hospital_patients_week: "Hospital patients this week",
  hospitalization: "Hospitalization",
  hospitalization_complated: "Hospitalization completed",
  hospitalization_type: "Type of hospitalization",
  hour: "Hour",
  house: "House",
  how_many_days: "How many days",
  how_prepare_medicine: "How to prepare medicines",
  id_code: "Identification code",
  id_number: "Order ID",
  ifo: "I.F.O",
  income_product: "Incoming products",
  incoming: "Incoming",
  indicator: "Indicator",
  indicators: "Indicators",
  info_aggrement: "Agreement information",
  info_treatment: "Treatment information",
  inn: "Tax ID",
  input: "Enter",
  input_order_id_for_delete_status: "Delete status",
  instruction_for_use: "Usage instructions",
  insurance_company: "Insurance company",
  insurance_number: "Insurance number",
  integration: "Integration",
  integration_price: "Integration price",
  invalid: "Defective",
  invoice: "Invoice",
  invoice_insurance: "Insurance invoices",
  invoices: "Invoices",
  is_active: "Active",
  is_close: "Conclusion approved",
  is_conclusion: "Conclusion",
  is_register: "Registration",
  is_report: "Full report",
  lab_nurse: "Sample collection",
  laborant: "Laboratory assistant",
  laboratory: "Laboratory",
  lack_of: "Missing",
  last_login: "Last login",
  lasting: "Duration",
  legal_address: "Legal address",
  lend: "Lend",
  letterhead: "Partner",
  life_time: "Lifetime",
  lifetime: "Lifetime",
  lifetime_services: "Lifetime services",
  light: "Light",
  limit: "Limit",
  load: "Load",
  loading: "Loading",
  locked: "Locked",
  log_out: "Log out",
  login: "Authorization",
  logo: "Logo",
  main: "Main",
  main_warehouses: "Main warehouses",
  male: "Male",
  man: "Male",
  manufactured_date: "Manufacture date",
  material: "Material",
  materials_consuption: "Material consumption",
  materials_consuptions: "Material consumptions",
  measurement: "Measurement",
  measurement_full: "Measurement unit",
  measurements: "Measurements",
  medical_history: "Medical history",
  medicine: "Products",
  medicines: "Medicines",
  medicines_on: "Medicines N/O",
  men: "Men",
  mfo: "MFO",
  minut: "Min.",
  modules: "Modules",
  money_per_day: "Price per day",
  month: "Month",
  monthly: "Monthly",
  movement: "Movement",
  my_conclusions: "My conclusions",
  my_informal_laboratory_patients: "Laboratory orders",
  my_informal_patients: "Doctor orders",
  my_laboratory: "Laboratory",
  my_office: "My office",
  my_patients: "My patients",
  name: "Name",
  name_0: "Name",
  name_service: "Service name",
  name_template: "Template name",
  nationality: "Nationality",
  nds: "VAT",
  needed: "Needed",
  new: "New",
  new_aggrement: "New agreement",
  new_category_service: "New service category",
  new_client: "New client",
  new_country: "New country",
  new_daily_process_report: "New process report",
  new_discount: "New discount",
  new_expence: "New expense",
  new_groups: "New group",
  new_measurements: "New unit of measure",
  new_order: "New order",
  new_owner_clinic: "New branding",
  new_partner_clinic: "New partner clinic",
  new_partner_doctor: "New partner doctor",
  new_patient: "Add patient",
  new_payment_type: "New payment type",
  new_photo: "Add image",
  new_price: "Price for one quantity",
  new_product: "New product",
  new_project: "New project",
  new_report: "New report",
  new_role: "New role",
  new_room: "New room",
  new_service: "New service",
  new_source: "New source",
  new_specialities: "New specialty",
  new_status: "New status",
  new_supplier: "New supplier",
  new_system: "New system",
  new_tag: "New tag",
  new_tariff: "New tariff",
  new_task: "New task",
  new_time: "New time",
  new_type_document: "New document type",
  new_type_expence: "New expense type",
  new_user: "New user",
  new_warehouse: "New warehouse",
  next: "Next",
  no: "No",
  normaCost: "Expense standard",
  normaLaboratory: "Laboratory standard",
  not_full: "Incomplete",
  not_paid: "Unpaid",
  not_paid_invoices: "Unpaid invoices",
  not_paid_of_all: "Nothing paid",
  not_paid_sum: "Unpaid amount",
  not_set: "Not specified",
  note: "Note",
  notes: "Notes",
  npt_active: "Inactive",
  number: "Number",
  number_aggrement: "Agreement number",
  number_card: "Outpatient card number",
  number_of_closed_conclusions_month: "Approved conclusions this month",
  number_of_orders_month: "Orders this month",
  number_of_orders_today: "Orders today",
  number_of_orders_total: "Total orders",
  number_of_patients_month: "Patients this month",
  number_of_patients_today: "Patients today",
  number_of_patients_total: "Total patients",
  number_of_patients_week: "Patients this week",
  number_of_written_conclusions_month: "Conclusions written this month",
  nurse_office: "Nurse's office",
  nurse_wrong_choice: "Nurse made a wrong choice of cells",
  oked: "OKED",
  okonx: "OKONH",
  old: "Old",
  on_close: "On close",
  on_payment: "On payment",
  once_price: "Unit price",
  onlis: "ONLIS",
  open: "Open",
  operation_canceled: "Operation canceled",
  order: "Order",
  orderServiceTerm: "Orders by dates",
  order_amount: "Service amount",
  order_amput: "Service amount",
  order_column: "Create fields",
  order_count: "Number of orders",
  order_created_at: "Order received date",
  order_date: "Order date",
  order_id: "Order ID",
  order_not_found: "Order not found",
  order_number: "Order number",
  order_payment: "Order payment",
  order_service: "Order service",
  order_updated_at: "Execution date",
  orders: "Orders",
  organization: "Organization",
  outgoing: "Outgoing",
  outpatient_card: "Outpatient card",
  outsource: "Outsource",
  outsource_add: "Add outsourcing",
  outsource_party: "Outsourcing batch",
  outsourcing_files: "Outsourcing file",
  overpaid: "Overpaid",
  overpaid_amount: "Excess amount",
  own_doctor: "Our doctor",
  owner_branch: "Branches",
  owner_clinic: "Branding",
  package_quantity: "Quantity in package",
  page_header: "Page header",
  paid: "Paid",
  paid_invoices: "Paid invoices",
  paid_partner_bonus: "Partner bonuses paid",
  paid_price: "Amount paid",
  parent: "Parent",
  parent_category: "Parent category",
  parent_service: "Parent services",
  partner_amount: "Partner amount",
  partner_bonus: "Partner bonuses",
  partner_clinic: "Partner clinic",
  partner_clinic_full_logo: "Full logo",
  partner_clinic_full_logo_active: "Full logo active",
  partner_clinic_mini_logo: "Mini logo",
  partner_clinic_mini_logo_active: "Mini logo active",
  partner_clinics: "Partner clinics",
  partner_doctor: "Partner doctor",
  partners: "Partners",
  party_services: "Party services",
  passport_number: "Passport number",
  password: "Password",
  patient: "Patient",
  patientResidenceReport: "District report",
  patientServiceReport: "Service report",
  patient_0: "Patient",
  patient_age_reports: "Age reports",
  patient_ages: "Patient ages",
  patient_balance: "Patient balance",
  patient_conclusions: "Patient conclusions",
  patient_histories: "Patient histories",
  patient_history: "Patient histories",
  patient_hospitalization: "Patient hospitalization",
  patient_id: "Patient ID",
  patient_indicator: "Patient indicator",
  patient_indicators: "Patient indicators",
  patient_info: "Patient information",
  patient_name: "Patient name",
  patient_or_fio: "Patient No. or Full name",
  patient_physiotherapy: "Patient physiotherapy",
  patient_reject: "Patient refused",
  patient_report: "All patient conclusions",
  patient_service: "Patient services",
  patients: "Patients",
  patients2: "Patients",
  patients_count: "Number of patients",
  patients_income: "Total clinic income",
  patronymic: "Patronymic",
  pay: "Pay",
  pay_order: "Pay orders",
  payable_sum: "Payable amount",
  payment: "Payment",
  paymentBonusP: "Bonus Payments",
  payment_report_limit: "Payment report limit",
  payment_reports: "Payment reports",
  payment_type: "Payment type",
  payment_types: "Payment types",
  payments: "Payments",
  payments_for_expenses: "Payments for expenses",
  payments_report: "Payments report",
  percent: "Sale %",
  percent_: "%",
  percentage: "Percentage",
  period: "Period",
  phone: "Phone",
  phone_number: "Phone number",
  phone_number_is_not_valid: "This phone number is invalid",
  phone_numbers: "Phone numbers",
  phone_two: "Phone 2",
  photo: "Photo (3x4 cm)",
  phy_office: "Physiotherapy office",
  physiotherapy: "Physiotherapy",
  pieces: "pcs.",
  placeholder_time_date: "Choose date and time",
  please_choose_parent: "Please choose a parent category",
  please_choose_role: "Please choose a role",
  please_enter_parol: "Please enter the password",
  pockets: "Pockets",
  position: "Position",
  pp: "P.P.",
  preparing: "In preparation",
  price: "Cost",
  price2: "Price",
  price_aggrement: "Agreement amount",
  price_one_day_min_room_price: "Minimum package price per bunk for 1 day",
  price_one_day_with_room_price: "Package price for 1 day with bunk",
  price_one_day_without_room_price: "Package price for 1 day",
  price_per_day: "Price per day",
  price_with_room_price: "Package price for {days} day(s) with bunk",
  print: "Print",
  print_audit: "Print audit",
  print_center: "Print center",
  print_conclusion: "Print conclusion",
  print_double: "Print 2x",
  print_form: "Print form",
  print_hitory: "Print history",
  print_insurance: "Medical card",
  print_qr: "Print QR code",
  printing: "Printing",
  procedural: "Procedural",
  procedural_0: "Procedural",
  procedural_list: "Procedural list",
  process: "Process",
  process_l: "L.P.D processes",
  processes: "Processes",
  procurement: "Procurement",
  product: "Product",
  product_detail: "Product details",
  products: "Products",
  profile: "Profile",
  project: "Project",
  project_name: "Project name",
  projects: "Projects",
  provision: "Condition",
  puls: "BP",
  purchase_price: "Purchase price",
  purchase_type: "Purchase type",
  purchasing_products: "Product procurement",
  purpose_of_hospitalization: "Purpose of hospitalization",
  purpose_quantity: "Purpose quantity",
  qty_come: "Incoming qty.",
  quantity: "Quantity",
  quantity_short: "Qty",
  quarantine: "Quarantine",
  quarterly: "Quarterly",
  rate: "Rate",
  raw_producement: "Raw material procurement",
  reason: "Reason",
  receive: "Incoming",
  receive_: "Receive",
  reception_visit: "Visit reception",
  recipient: "Recipient",
  refresh: "Refresh",
  refund: "Refund",
  region: "Region",
  reject: "Reject",
  rejected: "Rejected",
  remaind: "Remaining",
  remainder: "Remaining",
  remainder_reports: "Procedural report",
  remainders: "Remainders",
  replacement: "Replacement",
  report_clinics: "Clinic report",
  report_doctors: "Doctor report",
  report_insurance_company: "Insurance company report",
  report_partner_clinics: "Partner clinics report",
  report_partner_doctors: "Partner doctors report",
  report_warehouse: "Orders report",
  reports: "Reports",
  requirement: "Requirement",
  requirements: "Requirements",
  reset: "Reset",
  resident_price: "Price for non-residents",
  resourcesText: "Resources",
  responsible_name: "Representative",
  return_material: "Return material",
  reverse: "Reverse",
  reverse_rate: "Reverse rate",
  rkp_nds: "RKP VAT",
  role: "Role",
  role_name: "Role name",
  roles: "Roles",
  room: "Room",
  rooms: "Rooms",
  rooms_number: "Room number",
  running_title: "Running title",
  saldo: "Balance",
  sale_amount: "Sale amount",
  sale_percentage: "Sale percentage",
  sale_price: "Sale price",
  save: "Save",
  save_and_close: "Save and close",
  save_and_create_new: "Save and add new",
  save_and_go: "Save and continue",
  schedule: "Schedule",
  schedule_cabinet: "Cabinet schedule",
  score: "Accounts",
  scores: "Accounts",
  search: "Search",
  search_0: "Search",
  search_doctor: "Search doctor",
  search_order: "Find orders",
  search_order0: "Find order",
  search_template: "Find template",
  secondary_doctor: "Referring doctor",
  select_patient: "Choose a patient",
  select_patient_or_find: "Choose a patient on the left or add a new one",
  select_time: "Choose date and time",
  send: "Send",
  sent: "Sent",
  serial_number: "Serial number",
  series_and_number: "Series and number",
  service: "Service",
  service_category: "Service category",
  service_categoy: "Service category",
  service_name: "Service name",
  service_type: "Service type",
  service_type_id: "Service category ID",
  service_type_name: "Service type name",
  service_types: "Service types",
  services: "Services",
  services_count: "Number of services",
  set_header_footer: "Set header and footer",
  set_sign: "Set signature",
  set_transaction_amount: "Specify transaction amount",
  settings: "Settings",
  short_name: "Short name",
  show: "Shown",
  show_0: "Show",
  show_audit: "Show audit",
  show_conclusions: "Show conclusions",
  show_drug: "View medicine",
  show_valid_price: "Specify medicine cost",
  showing: "View",
  sickNumber: "ICD code",
  sign_in: "Sign in",
  sku: "SKU",
  slug: "Unique identifier",
  social: "Source",
  source: "Source",
  specialities: "Specialties",
  speciality: "Specialty",
  speciality_doctor: "Doctor specialty",
  startPeriod: "From date",
  start_date: "From date",
  start_input_find_service: "Start typing the service name or article",
  start_period: "From",
  stasionary_cashier: "Stationary cashier",
  static_info: "Statistics",
  stationary: "Stationary",
  stationary_settings: "Stationary settings",
  status: "Statuses",
  status_0: "Status",
  status_closed: "Successful",
  status_condition: "Condition status",
  status_doctor: "With doctor",
  status_doctor_free: "Doctor orders",
  status_lab_nurse: "Sample collection",
  status_laborant: "With laboratory assistant",
  status_laborant_free: "Laboratory orders",
  status_nurse: "Sample collection",
  status_payment: "In payment",
  status_semi_created: "Awaiting confirmation",
  status_waiting_paid: "Awaiting payment",
  status_written: "Conclusion written",
  street: "Street",
  subject_of_agreement: "Agreement subject (services)",
  sum: "Sum",
  sum_expence: "Expense sum",
  sum_receive: "Income sum",
  summ: "Sum",
  suppliers: "Suppliers",
  suppliers_aggrement: "Supplier agreements",
  surname: "Surname",
  symbol: "Symbol",
  system_settings: "System settings",
  systems: "Systems",
  tags: "Tags",
  tariff: "Tariff",
  tariffs: "Tariffs",
  task_type: "Task type",
  task_types: "Task types",
  tasks: "Tasks",
  tel_number: "Phone number",
  temperature: "Temperature",
  templates: "Templates",
  term: "Expiration date",
  term_aggrement: "Agreement term",
  terminal: "Terminal",
  test: "Test",
  theme_setting: "Theme settings",
  time: "Time",
  times: "Time",
  title: "Title",
  to_history: "To history",
  to_time: "End time",
  today_transactions: "Total amount for today",
  top_services: "Top services",
  total: "Total",
  total_0: "Total",
  total_amount: "Total amount",
  total_cost: "Total cost",
  total_price: "Total amount",
  total_reports: "Total reports",
  totals: "Totals",
  totaly: "Overall",
  transactionScores: "Balance",
  transactions: "Transactions",
  transmitter: "Sender",
  type: "Type",
  type_condition: "Type of condition",
  type_documents: "Document type",
  type_expence: "Expense type",
  type_expences: "Expense types",
  type_of_injury: "Type of injury",
  type_phone: "Phone type",
  type_receive: "Incoming types",
  typebonus: "Bonus type",
  unnecessary_enclosure: "Unnecessary conclusion",
  until: "Until",
  update: "Update",
  update_access_rights: "Update access rights",
  update_branch: "Update branch",
  update_bunk: "Update bunk",
  update_category_service: "Update service category",
  update_citizenship: "Update citizenship",
  update_city: "Update city",
  update_class: "Update class",
  update_client: "Update client",
  update_condition: "Update condition",
  update_conditions: "Update condition",
  update_corps: "Update corps",
  update_country: "Update country",
  update_currency: "Update currency",
  update_date: "Update date",
  update_department_user: "Update department user",
  update_departmentes: "Update department",
  update_discount: "Update discount",
  update_doctor_sign: "Update signature",
  update_expence: "Update expense",
  update_floor: "Update floor",
  update_hospitalization: "Update hospitalization",
  update_hospitalization_type: "Update hospitalization type",
  update_module: "Update module",
  update_movement: "Update movement",
  update_nationality: "Update nationality",
  update_new_patient: "Update new patient",
  update_organization: "Update organization",
  update_partner_clinic: "Update partner clinic",
  update_partner_doctor: "Update partner doctor",
  update_patient: "Update patient",
  update_patient_indicators: "Update patient indicators",
  update_payment_type: "Update payment type",
  update_product: "Update product",
  update_project: "Update project",
  update_purpose_of_hospitalization: "Update purpose of hospitalization",
  update_region: "Update region",
  update_report: "Update report",
  update_role: "Update role",
  update_room: "Update room",
  update_service: "Update service",
  update_setting: "Update",
  update_source: "Update source",
  update_specialities: "Update specialty",
  update_system: "Update system",
  update_tags: "Update tag",
  update_tariff: "Update tariff",
  update_task: "Update task",
  update_teg: "Update tag",
  update_time: "Update time",
  update_type_document: "Update document type",
  update_type_expence: "Update expense type",
  update_type_of_injury: "Update type of injury",
  used: "Used",
  used_cassettes: "Used cassettes",
  used_glasses: "Used glasses",
  used_medicine: "Used procedural",
  user: "User",
  user_amount: "Number of users",
  username: "Owner:",
  users: "Users",
  valid: "Medicines",
  validation: "Error",
  version: "Version",
  vial_quantity: "Number of vials",
  view: "View",
  view_0: "View",
  warehouse: "Warehouse",
  warehouse_movement: "Warehouse movements",
  warehouses: "Warehouses",
  warehouses_0: "Warehouses",
  warning: "Warning",
  web_site: "Website",
  website_name: "Specify the website name",
  week: "Week",
  weekly: "Weekly",
  weight: "Weight",
  whence: "Where from",
  where_to: "Where to",
  without_amount: "Without calculation",
  without_calculation: "Contract No.",
  woman: "Woman",
  women: "Women",
  work_schedule: "Work schedule",
  write_conclusions: "Write a conclusion",
  write_of_from: "Write-off date from",
  write_off: "Write-off",
  write_off_date: "Write-off date",
  write_off_items: "Items for write-off",
  write_off_products: "Write-off products",
  write_off_quantity: "Write-off quantity",
  write_offs: "Write-offs",
  written_conclusions: "Conclusions written",
  year: "Year",
  yearly: "Yearly",
  yes: "Yes",
  yes_doctor: "Contact the doctor",
};
